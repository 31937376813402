/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function SEO({ author, description, lang, meta, siteData, slug, title,  }) {
  const metaTitle = title || siteData?.siteMetadata?.title || '';
  const metaDescription = description || siteData?.siteMetadata?.description || '';
  const metaSiteUrl = siteData?.siteMetadata?.siteUrl ? `${siteData.siteMetadata.siteUrl}${slug ? `/${slug}` : ''}` : '';

  const links = [];

  if (metaSiteUrl) {
    links.push({ rel: 'canonical', href: metaSiteUrl});
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s${metaTitle ? ` | ${metaTitle}` : ``}`}
      link={links}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  siteData: PropTypes.object,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
  slug: PropTypes.string,
};

export default SEO;
